import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _74cdc79c = () => interopDefault(import('../client/pages/cart/index.vue' /* webpackChunkName: "" */))
const _aca60f18 = () => interopDefault(import('../client/pages/products/no-minimum/_.vue' /* webpackChunkName: "" */))
const _5fbdf248 = () => interopDefault(import('../client/pages/products/closeout/_.vue' /* webpackChunkName: "" */))
const _bc6aeff2 = () => interopDefault(import('../client/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _14d21b9c = () => interopDefault(import('../client/pages/cartitemsadded/index.vue' /* webpackChunkName: "pages/cartitemsadded/index" */))
const _684f2bc7 = () => interopDefault(import('../client/pages/changepassword/index.vue' /* webpackChunkName: "pages/changepassword/index" */))
const _c0625490 = () => interopDefault(import('../client/pages/customer-artwork-proof/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/index" */))
const _0960ba4f = () => interopDefault(import('../client/pages/designs/index.vue' /* webpackChunkName: "pages/designs/index" */))
const _755c4ed8 = () => interopDefault(import('../client/pages/facets/index.vue' /* webpackChunkName: "pages/facets/index" */))
const _749cf7f1 = () => interopDefault(import('../client/pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _446c247a = () => interopDefault(import('../client/pages/forgotpassword/index.vue' /* webpackChunkName: "pages/forgotpassword/index" */))
const _153e6656 = () => interopDefault(import('../client/pages/health.vue' /* webpackChunkName: "pages/health" */))
const _68409062 = () => interopDefault(import('../client/pages/not-found/index.vue' /* webpackChunkName: "pages/not-found/index" */))
const _3f5cca1e = () => interopDefault(import('../client/pages/proof/index.vue' /* webpackChunkName: "pages/proof/index" */))
const _c3ad38c4 = () => interopDefault(import('../client/pages/proofitemsadded/index.vue' /* webpackChunkName: "pages/proofitemsadded/index" */))
const _ab3be654 = () => interopDefault(import('../client/pages/quoteitemsadded/index.vue' /* webpackChunkName: "pages/quoteitemsadded/index" */))
const _4e341344 = () => interopDefault(import('../client/pages/resetpassword/index.vue' /* webpackChunkName: "pages/resetpassword/index" */))
const _11262e34 = () => interopDefault(import('../client/pages/sampleitemsadded/index.vue' /* webpackChunkName: "pages/sampleitemsadded/index" */))
const _6598ad5e = () => interopDefault(import('../client/pages/signin/index.vue' /* webpackChunkName: "pages/signin/index" */))
const _88fed56a = () => interopDefault(import('../client/pages/signout/index.vue' /* webpackChunkName: "pages/signout/index" */))
const _e3186c7e = () => interopDefault(import('../client/pages/suppliers/index.vue' /* webpackChunkName: "pages/suppliers/index" */))
const _1c04cf41 = () => interopDefault(import('../client/pages/testy/index.vue' /* webpackChunkName: "pages/testy/index" */))
const _5460b9af = () => interopDefault(import('../client/pages/version.vue' /* webpackChunkName: "pages/version" */))
const _402a9974 = () => interopDefault(import('../client/pages/version-app/index.vue' /* webpackChunkName: "pages/version-app/index" */))
const _830f6dc4 = () => interopDefault(import('../client/pages/cat/GeneralProductView.vue' /* webpackChunkName: "pages/cat/GeneralProductView" */))
const _afd1ca30 = () => interopDefault(import('../client/pages/orders/products/index.vue' /* webpackChunkName: "pages/orders/products/index" */))
const _383f2af0 = () => interopDefault(import('../client/pages/products/utils.js' /* webpackChunkName: "pages/products/utils" */))
const _33a4905a = () => interopDefault(import('../client/pages/products/search/mixin.js' /* webpackChunkName: "pages/products/search/mixin" */))
const _07c9ecf2 = () => interopDefault(import('../client/pages/products/search/search.js' /* webpackChunkName: "pages/products/search/search" */))
const _1cc9756b = () => interopDefault(import('../client/pages/sso/facebook/delete.vue' /* webpackChunkName: "pages/sso/facebook/delete" */))
const _1156c071 = () => interopDefault(import('../client/pages/review/order/_orderId/product/_productId.vue' /* webpackChunkName: "pages/review/order/_orderId/product/_productId" */))
const _f689e2cc = () => interopDefault(import('../client/pages/products/search/_.vue' /* webpackChunkName: "pages/products/search/_" */))
const _5cb31732 = () => interopDefault(import('../client/pages/blog/cat/_.vue' /* webpackChunkName: "pages/blog/cat/_" */))
const _5bc52420 = () => interopDefault(import('../client/pages/approval/_orderId/index.vue' /* webpackChunkName: "pages/approval/_orderId/index" */))
const _47f949a3 = () => interopDefault(import('../client/pages/artproof/_productSlug/index.vue' /* webpackChunkName: "pages/artproof/_productSlug/index" */))
const _28b809b0 = () => interopDefault(import('../client/pages/authors/_authorName/index.vue' /* webpackChunkName: "pages/authors/_authorName/index" */))
const _092e812c = () => interopDefault(import('../client/pages/components/_componentId/index.vue' /* webpackChunkName: "pages/components/_componentId/index" */))
const _1dc1c9ca = () => interopDefault(import('../client/pages/configureproduct/_productSlug/index.vue' /* webpackChunkName: "pages/configureproduct/_productSlug/index" */))
const _435654a3 = () => interopDefault(import('../client/pages/customer-artwork-proof/_id/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/_id/index" */))
const _267b0f8e = () => interopDefault(import('../client/pages/designer/_productSlug/index.vue' /* webpackChunkName: "pages/designer/_productSlug/index" */))
const _220b7e4a = () => interopDefault(import('../client/pages/designs/_slug/index.vue' /* webpackChunkName: "pages/designs/_slug/index" */))
const _61846fda = () => interopDefault(import('../client/pages/facets/_slug/index.vue' /* webpackChunkName: "pages/facets/_slug/index" */))
const _6a374e83 = () => interopDefault(import('../client/pages/largequote/_productSlug/index.vue' /* webpackChunkName: "pages/largequote/_productSlug/index" */))
const _03b16b4c = () => interopDefault(import('../client/pages/orders/_id/index.vue' /* webpackChunkName: "pages/orders/_id/index" */))
const _13148e74 = () => interopDefault(import('../client/pages/payments/_orderId/index.vue' /* webpackChunkName: "pages/payments/_orderId/index" */))
const _17a7a839 = () => interopDefault(import('../client/pages/presentation/_orderId/index.vue' /* webpackChunkName: "pages/presentation/_orderId/index" */))
const _4ea7ccc4 = () => interopDefault(import('../client/pages/presentations/_id/index.vue' /* webpackChunkName: "pages/presentations/_id/index" */))
const _81035c9a = () => interopDefault(import('../client/pages/printinvoice/_orderId/index.vue' /* webpackChunkName: "pages/printinvoice/_orderId/index" */))
const _5847b39b = () => interopDefault(import('../client/pages/products/_slug/index.vue' /* webpackChunkName: "pages/products/_slug/index" */))
const _6f30b3ee = () => interopDefault(import('../client/pages/proof/_id/index.vue' /* webpackChunkName: "pages/proof/_id/index" */))
const _879ecb96 = () => interopDefault(import('../client/pages/quickquote/_productSlug/index.vue' /* webpackChunkName: "pages/quickquote/_productSlug/index" */))
const _3a811dc0 = () => interopDefault(import('../client/pages/reorder/_id/index.vue' /* webpackChunkName: "pages/reorder/_id/index" */))
const _125a565a = () => interopDefault(import('../client/pages/sample/_productSlug/index.vue' /* webpackChunkName: "pages/sample/_productSlug/index" */))
const _3d4b1ba2 = () => interopDefault(import('../client/pages/sso/_service.vue' /* webpackChunkName: "pages/sso/_service" */))
const _3ab21d60 = () => interopDefault(import('../client/pages/cat/_slug/brand.vue' /* webpackChunkName: "pages/cat/_slug/brand" */))
const _528aba56 = () => interopDefault(import('../client/pages/orders/_id/track-shipment/index.vue' /* webpackChunkName: "pages/orders/_id/track-shipment/index" */))
const _76bec85d = () => interopDefault(import('../client/pages/printinvoice/_orderId/short.vue' /* webpackChunkName: "pages/printinvoice/_orderId/short" */))
const _b3b7abb8 = () => interopDefault(import('../client/pages/products/_slug/reviews.vue' /* webpackChunkName: "pages/products/_slug/reviews" */))
const _1335d7ec = () => interopDefault(import('../client/pages/facets/_slug/_facet/index.vue' /* webpackChunkName: "pages/facets/_slug/_facet/index" */))
const _3efcaa75 = () => interopDefault(import('../client/pages/supplier/_psCode/_.vue' /* webpackChunkName: "pages/supplier/_psCode/_" */))
const _12072eb8 = () => interopDefault(import('../client/pages/cat/_slug/_.vue' /* webpackChunkName: "pages/cat/_slug/_" */))
const _1251dcf6 = () => interopDefault(import('../client/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/box/cart",
    component: _74cdc79c,
    name: "box/cart"
  }, {
    path: "/quote/cart",
    component: _74cdc79c,
    name: "quote/cart"
  }, {
    path: "/sample/cart",
    component: _74cdc79c,
    name: "sample/cart"
  }, {
    path: "/proof/cart",
    component: _74cdc79c,
    name: "proof/cart"
  }, {
    path: "/boxcart",
    component: _74cdc79c,
    name: "boxcart"
  }, {
    path: "/quotecart",
    component: _74cdc79c,
    name: "quotecart"
  }, {
    path: "/samplecart",
    component: _74cdc79c,
    name: "samplecart"
  }, {
    path: "/proofcart",
    component: _74cdc79c,
    name: "proofcart"
  }, {
    path: "/products/no-minimum",
    component: _aca60f18,
    name: "products-no-minimum"
  }, {
    path: "/products/closeout",
    component: _5fbdf248,
    name: "products-closeout"
  }, {
    path: "/account",
    component: _bc6aeff2,
    name: "account"
  }, {
    path: "/cart",
    component: _74cdc79c,
    name: "cart"
  }, {
    path: "/cartitemsadded",
    component: _14d21b9c,
    name: "cartitemsadded"
  }, {
    path: "/changepassword",
    component: _684f2bc7,
    name: "changepassword"
  }, {
    path: "/customer-artwork-proof",
    component: _c0625490,
    name: "customer-artwork-proof"
  }, {
    path: "/designs",
    component: _0960ba4f,
    name: "designs"
  }, {
    path: "/facets",
    component: _755c4ed8,
    name: "facets"
  }, {
    path: "/favorites",
    component: _749cf7f1,
    name: "favorites"
  }, {
    path: "/forgotpassword",
    component: _446c247a,
    name: "forgotpassword"
  }, {
    path: "/health",
    component: _153e6656,
    name: "health"
  }, {
    path: "/not-found",
    component: _68409062,
    name: "not-found"
  }, {
    path: "/proof",
    component: _3f5cca1e,
    name: "proof"
  }, {
    path: "/proofitemsadded",
    component: _c3ad38c4,
    name: "proofitemsadded"
  }, {
    path: "/quoteitemsadded",
    component: _ab3be654,
    name: "quoteitemsadded"
  }, {
    path: "/resetpassword",
    component: _4e341344,
    name: "resetpassword"
  }, {
    path: "/sampleitemsadded",
    component: _11262e34,
    name: "sampleitemsadded"
  }, {
    path: "/signin",
    component: _6598ad5e,
    name: "signin"
  }, {
    path: "/signout",
    component: _88fed56a,
    name: "signout"
  }, {
    path: "/suppliers",
    component: _e3186c7e,
    name: "suppliers"
  }, {
    path: "/testy",
    component: _1c04cf41,
    name: "testy"
  }, {
    path: "/version",
    component: _5460b9af,
    name: "version"
  }, {
    path: "/version-app",
    component: _402a9974,
    name: "version-app"
  }, {
    path: "/cat/GeneralProductView",
    component: _830f6dc4,
    name: "cat-GeneralProductView"
  }, {
    path: "/orders/products",
    component: _afd1ca30,
    name: "orders-products"
  }, {
    path: "/products/utils",
    component: _383f2af0,
    name: "products-utils"
  }, {
    path: "/products/search/mixin",
    component: _33a4905a,
    name: "products-search-mixin"
  }, {
    path: "/products/search/search",
    component: _07c9ecf2,
    name: "products-search-search"
  }, {
    path: "/sso/facebook/delete",
    component: _1cc9756b,
    name: "sso-facebook-delete"
  }, {
    path: "/review/order/:orderId?/product/:productId?",
    component: _1156c071,
    name: "review-order-orderId-product-productId"
  }, {
    path: "/products/search/*",
    component: _f689e2cc,
    name: "products-search-all"
  }, {
    path: "/products/no-minimum/*",
    component: _aca60f18,
    name: "products-no-minimum-all"
  }, {
    path: "/products/closeout/*",
    component: _5fbdf248,
    name: "products-closeout-all"
  }, {
    path: "/blog/cat/*",
    component: _5cb31732,
    name: "blog-cat-all"
  }, {
    path: "/approval/:orderId",
    component: _5bc52420,
    name: "approval-orderId"
  }, {
    path: "/artproof/:productSlug",
    component: _47f949a3,
    name: "artproof-productSlug"
  }, {
    path: "/authors/:authorName",
    component: _28b809b0,
    name: "authors-authorName"
  }, {
    path: "/components/:componentId",
    component: _092e812c,
    name: "components-componentId"
  }, {
    path: "/configureproduct/:productSlug",
    component: _1dc1c9ca,
    name: "configureproduct-productSlug"
  }, {
    path: "/customer-artwork-proof/:id",
    component: _435654a3,
    name: "customer-artwork-proof-id"
  }, {
    path: "/designer/:productSlug",
    component: _267b0f8e,
    name: "designer-productSlug"
  }, {
    path: "/designs/:slug",
    component: _220b7e4a,
    name: "designs-slug"
  }, {
    path: "/facets/:slug",
    component: _61846fda,
    name: "facets-slug"
  }, {
    path: "/largequote/:productSlug",
    component: _6a374e83,
    name: "largequote-productSlug"
  }, {
    path: "/orders/:id",
    component: _03b16b4c,
    name: "orders-id"
  }, {
    path: "/payments/:orderId",
    component: _13148e74,
    name: "payments-orderId"
  }, {
    path: "/presentation/:orderId",
    component: _17a7a839,
    name: "presentation-orderId"
  }, {
    path: "/presentations/:id",
    component: _4ea7ccc4,
    name: "presentations-id"
  }, {
    path: "/printinvoice/:orderId",
    component: _81035c9a,
    name: "printinvoice-orderId"
  }, {
    path: "/products/:slug",
    component: _5847b39b,
    name: "products-slug"
  }, {
    path: "/proof/:id",
    component: _6f30b3ee,
    name: "proof-id"
  }, {
    path: "/quickquote/:productSlug",
    component: _879ecb96,
    name: "quickquote-productSlug"
  }, {
    path: "/reorder/:id",
    component: _3a811dc0,
    name: "reorder-id"
  }, {
    path: "/sample/:productSlug",
    component: _125a565a,
    name: "sample-productSlug"
  }, {
    path: "/sso/:service?",
    component: _3d4b1ba2,
    name: "sso-service"
  }, {
    path: "/cat/:slug?/brand",
    component: _3ab21d60,
    name: "cat-slug-brand"
  }, {
    path: "/orders/:id?/track-shipment",
    component: _528aba56,
    name: "orders-id-track-shipment"
  }, {
    path: "/printinvoice/:orderId?/short",
    component: _76bec85d,
    name: "printinvoice-orderId-short"
  }, {
    path: "/products/:slug?/reviews",
    component: _b3b7abb8,
    name: "products-slug-reviews"
  }, {
    path: "/facets/:slug/:facet",
    component: _1335d7ec,
    name: "facets-slug-facet"
  }, {
    path: "/supplier/:psCode?/*",
    component: _3efcaa75,
    name: "supplier-psCode-all"
  }, {
    path: "/cat/:slug?/*",
    component: _12072eb8,
    name: "cat-slug-all"
  }, {
    path: "/*",
    component: _1251dcf6,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
